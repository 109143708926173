const usePopupsInit = () => {
	const route = useRoute();
	const { open, isOpen } = useFunrizeModals();
	const { checkRestoreLink } = useAccountChangePassword();
	const { data: appInitData } = useAppInitData();
	const { showDailyWheelPopup } = useWheels();
	const { popupState, resetPopupState } = usePopupsState();
	const isGuest = useIsGuest();
	const { isFreeSpinQuest, isAdventQuest } = useGameQuest();

	const { openModal, game, "change-password": changePasswordToken } = route.query;

	const isClosedFunnel = computed(
		() => appInitData.value?.Registration?.registrationFunnel?.funnelType === "closed_funnel"
	);

	const runDepositStreakModalsFlow = () => {
		if (isGuest.value) {
			return;
		}

		if (!appInitData.value?.depositStreak?.isActive) {
			localStorage.removeItem("showDepositStreakIntro");
			return;
		}

		const skipDepositStreakIndexCookie = useCookie("skipDepositStreakIndex", {
			path: "/",
			maxAge: 60 * 60 * 24
		});

		if (localStorage.getItem("showDepositStreakIntro") !== "false") {
			localStorage.setItem("showDepositStreakIntro", "true");
			open("LazyOModalDepositStreakIntro");
			skipDepositStreakIndexCookie.value = "true";
			return;
		}

		if (!isDefined(skipDepositStreakIndexCookie.value)) {
			open("LazyOModalDepositStreak");
			skipDepositStreakIndexCookie.value = "true";
		}
	};

	watch(isGuest, (value, oldValue) => {
		if (value) {
			resetPopupState();
		} else if (!value && oldValue) {
			if (
				!isOpen("LazyOModalPhoneConfirmation") &&
				!isOpen("LazyOModalRegistrationComplete") &&
				(!isClosedFunnel.value || !isOpen("LazyOModalEmailConfirm"))
			) {
				if (appInitData.value?.season?.isEnabled && popupState.value.isLoginFlow) {
					open("LazyOModalSeasonWelcome", { isSoundOff: true });
				}

				runDepositStreakModalsFlow();

				setTimeout(() => {
					showDailyWheelPopup();
				}, 1000);
			}
		}
	});

	watch([() => route.path, () => route.query], ([, newQuery]) => {
		if (isGuest.value) {
			return;
		}

		if (
			newQuery.game &&
			!localStorage.getItem("showTowerModalInGamePopup") &&
			appInitData.value?.season?.isEnabled &&
			!appInitData.value?.season?.isActive
		) {
			setTimeout(() => {
				open("LazyOModalSeasonTower", { isSoundOff: true });
				localStorage.setItem("showTowerModalInGamePopup", "true");
			}, 1500);
		}
	});

	onMounted(() => {
		if (!isGuest.value) {
			if (route.query?.social || route?.query?.action) {
				setTimeout(() => {
					dispatchGAEvent({
						event: route.query?.action === "register" ? "registration" : "login",
						location: "header",
						type: (route.query?.social as string) ?? "",
						step: "success",
						playerId: appInitData.value?.login ?? ""
					});
				}, 1500);
			}
		}

		if (game) {
			open("LazyOModalGame");
			return;
		}

		showDailyWheelPopup();

		if (changePasswordToken) {
			checkRestoreLink();
		}

		if (openModal === "app-referral-popup") {
			open("LazyOInviteModal");
		}

		if (openModal === "daily-wheel") {
			if (isGuest.value) {
				open("LazyOModalSignup");
				return;
			}

			open("LazyOModalWheelDaily");
		}

		if (openModal === "app-season-modal" && appInitData.value?.season?.isEnabled) {
			open("LazyOModalSeasonTower");
		}
		if (openModal === "app-advent-modal" && isAdventQuest.value) {
			open("LazyOModalAdventCalendar");
		}
		if (openModal === "app-freeplay-modal" && isFreeSpinQuest.value && !isGuest.value) {
			open("LazyOModalFreeSpin");
		}
		if (openModal === "app-deposit-streak-modal" && appInitData.value?.depositStreak?.isActive && !isGuest.value) {
			open("LazyOModalDepositStreak");
		}

		runDepositStreakModalsFlow();
	});
};

export default usePopupsInit;
